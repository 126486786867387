<template>
  <TransitionRoot :show="open" as="template">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template"
                           enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100"
                           leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mx-0 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100">
                    <ExclamationTriangleIcon v-if="!showQuestionMark" aria-hidden="true" class="h-8 w-8 text-red-600"/>
                    <QuestionMarkCircleIcon v-if="showQuestionMark" aria-hidden="true" class="h-8 w-8 text-blue-600"/>
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ title }}
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">{{ message }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <MyButton v-if="showCancelButton" :disabled="clicked" ref="cancelButtonRef" @click="onCancel">Storno</MyButton>
                <MyButton :disabled="clicked" @click="onOk">OK</MyButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {ExclamationTriangleIcon, QuestionMarkCircleIcon} from '@heroicons/vue/24/outline'
import MyButton from "@/components/MyButton.vue";

const props = withDefaults(
    defineProps<{
      title?: string,
      message?: string,
      showCancelButton?: boolean,
      showQuestionMark?: boolean
    }>(),
    {
      title: '',
      message: '',
      showCancelButton: true,
      showQuestionMark: false
    });
const emit = defineEmits<{
  (e: 'onOk'): void,
  (e: 'onCancel'): void
}>();

const open = ref(false)
const clicked = ref(false)
const message = ref(props.message);
const title = ref(props.title);
const showCancelButton = ref(props.showCancelButton);

function onOk() {
  clicked.value = true;
  open.value = false;
  emit('onOk');
}

function onCancel() {
  clicked.value = true;
  open.value = false;
  emit('onCancel');
}

const show = (t: string, m: string) => {
  clicked.value = false;
  console.log(showCancelButton);
  if (t) {
    title.value = t;
  }

  if (m) {
    message.value = m;
  }

  open.value = true;
}

defineExpose({
  show
})

</script>