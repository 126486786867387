import {createRouter, createWebHistory} from "vue-router";
import HomePage from "./pages/HomePage.vue";
import LoginPage from "./pages/LoginPage.vue";
import LogoutPage from "./pages/LogoutPage.vue"
import {useAuthStore} from "@/stores/auth";

const routes = [
    {
        path: "/",
        // alias: ["/home"],
        name: "home",
        component: HomePage,
    },
    {
        path: '/index.html',
        redirect: '/'
    },
    {
        path: "/chat",
        name: "chat",
        component: HomePage,
    },
    {
        path: "/akreditace",
        name: "accreditation",
        component: HomePage,
    },
    {
        path: "/vstupenky",
        name: "tickets",
        component: HomePage,
    },
    {
        path: "/platba",
        name: "payment",
        component: HomePage,
    },
    {
        path: "/login",
        component: LoginPage,
    },
    {
        path: "/logout",
        name: "logout",
        component: LogoutPage
    }

];

// to tady bylo: base: process.env.BASE_URL,
const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/logout', '/css', '/img'];
    const authRequired = !publicPages.includes(to.path);

    if (authRequired && !useAuthStore().loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;