<template>
  <tr>
    <td class="text-right w-3">{{ props.item.number }}</td>
    <td>{{ TranslateService.partialAccreditationTranslate(props.item.name) }}</td>
    <td :class="props.item.exclude ? 'line-through' : ''" class="text-right">{{
        FormatService.toCurrency(props.item.price)
      }}
    </td>
    <td class="text-right">
      <ToggleSwitch v-model="excludeModel"/>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import ToggleSwitch from "./ToggleSwitch.vue";
import {computed} from "vue";
import {ISessionItem} from "@/services/contracts/ISessionItem";
import {ISessionitemChange} from "@/services/contracts/ISessionItemChange";
import FormatService from "../services/FormatService";
import TranslateService from "../services/TranslateService";

const props = defineProps<{
  item: ISessionItem
}>();

const emit = defineEmits<{
  excludeChanged: [change: ISessionitemChange]
}>();

const excludeModel = computed({
  get() {
    return props.item.exclude;
  },
  set(value: boolean) {
    emit('excludeChanged', {id: props.item.id, exclude: value})
  }
})

</script>

<style>

</style>