<template>
  <TransitionRoot :show="open" as="template">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template"
                           enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100"
                           leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
                class="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start justify-center">
                  <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <DialogTitle as="h3" class="font-bold leading-6 text-gray-900">{{ title }}</DialogTitle>
                    <slot></slot>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <MyButton v-show="showCancelButton" :disabled="clicked" @click="cancel">Storno</MyButton>
                <MyButton v-show="showOkButton" :disabled="clicked || !submittable" @click="ok">{{ props.okButtonText }}</MyButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>

    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue'
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import MyButton from "@/components/MyButton.vue";

export interface IMyDialogProps {
  title: string,
  okButtonText?: string,
  showOkButton?: boolean,
  showCancelButton?: boolean
}

const props = withDefaults(defineProps<IMyDialogProps>(), {
  okButtonText: 'OK',
  showOkButton: true,
  showCancelButton: true
});

const emit = defineEmits<{ ok: [], cancel: [] }>();

const open = ref(false);
const clicked = ref(false);
const title = computed(() => props.title);
const submittable = ref(true);

function ok() {
  clicked.value = true;
  open.value = false;
  emit('ok');
}

function cancel() {
  clicked.value = true;
  open.value = false;
  emit('cancel');
}

function show() {
  clicked.value = false;
  open.value = true;
}

function hide() {
  open.value = false;
}

function setSubmittable(_submittable: boolean) {
  submittable.value = _submittable;
}

defineExpose({
  show, hide, setSubmittable
})
</script>

<style>

</style>