import api from "./support/api";

class VersionService {

    private static readonly KEY = 'version';

    findVersion() {
        return api.get<string>('version');
    }

    get(): string {
        return localStorage.getItem(VersionService.KEY) ?? ''
    }

    set(version: string): void {
        localStorage.setItem(VersionService.KEY, version);
    }

}

export default new VersionService();
