import api from "./support/api";
import {IVendor} from "@/services/contracts/IVendor";
import {IChatMessage} from "@/services/contracts/IChatMessage";
import {IChatNewMessage} from "@/services/contracts/IChatNewMessage";

class ChatService {

    find(vendor: IVendor) {
        return api.get<Array<IChatMessage>>('chat/' + vendor.id);
    }

    addMessage(vendor: IVendor, data: IChatNewMessage) {
        return api.put<Array<IChatMessage>>('chat/' + vendor.id, data);
    }
}

export default new ChatService();
