import api from "./support/api";
import {IVendor} from "@/services/contracts/IVendor";

class VendorService {

    getVendor(): IVendor | null {
        const val = localStorage.getItem("vendor");
        if (val === null) {
            return null;
        }
        return JSON.parse(val);
    }

    setVendor(vendor: IVendor | null): void {
        if (vendor === null) {
            this.removeVendor();
        }
        localStorage.setItem("vendor", JSON.stringify(vendor));
    }

    removeVendor(): void {
        localStorage.removeItem("vendor");
    }

    findAvailableVendors() {
        return api.get<Array<IVendor>>('vendor/available');
    }

}

export default new VendorService();
