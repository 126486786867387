<template>
  <router-view/>
</template>

<style>
.h-screen {
  height: 100vh;
}
</style>
<script setup lang="ts">
</script>