<template>
  <div>
    <h3 class="text-center font-semibold leading-6 text-gray-900 mt-6">Načti kartu</h3>
    <CardReaderInput ref="cardReaderInput" @enterKeyUp="read"/>

    <div>
      <span>{{ cardReadingResult }}</span>
    </div>

    <h3 class="text-center font-semibold leading-6 text-gray-900 mt-3">{{ scannedCardsLabel }}</h3>
    <table class="min-w-full" v-if="cards.length > 0">
      <tr>
        <th class="text-sm">Číslo karty</th>
        <th class="text-sm text-right">Kategorie</th>
      </tr>
      <tr v-for="card in cards" :key="card.number">
        <td>{{ card.number }}</td>
        <td class="text-right">{{ translateService.translate(card.accreditationGroupKey) }}</td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts" setup>

import CardReaderInput from "../../CardReaderInput.vue";
import {computed, onUpdated, reactive, ref} from "vue";
import AccreditationService from "../../../services/AccreditationService";
import {IAccreditationRequest} from "@/services/contracts/IAccreditationRequest";
import {IScreening} from "@/services/contracts/IScreening";
import {ITicketPrintAccreditationPart} from "@/services/contracts/ITicketPrintAccreditationPart";
import TranslateService from "@/services/TranslateService";
import translateService from "@/services/TranslateService";

const props = defineProps<{
  modelValue: ITicketPrintAccreditationPart,
  screening: IScreening
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: ITicketPrintAccreditationPart): void
}>();

const cardReaderInput = ref();
const cardReadingResult = ref("");

//const data = props.modelValue;
const cards = reactive(props.modelValue.cards);

const scannedCardsLabel = computed(() => {
  const count = cards.length;
  if (count == 0) {
    return "Žádné načtené karty"
  }
  if (count == 1) {
    return "1 načtená karta"
  }
  if (count < 5) {
    return count + " načtené karty"
  }
  return count + " načtených karet"
});

function read(value: string) {
  const cardNumber = +value;
  cardReadingResult.value = "Kontroluji kartu ...";

  if (cards.find(c => c.number === cardNumber)) {
    cardReadingResult.value = "Karta je již načtena";
    return;
  }

  if (cards.length >= 4) {
    cardReadingResult.value = "Maximálně 4 karty najednout";
    return;
  }

  const checkAccreditationRequestData: IAccreditationRequest = {
    cardNumber: cardNumber,
    idScreening: props.screening.id
  }

  AccreditationService.checkAvailabilityForScreening(checkAccreditationRequestData).then(
      (response) => {
        console.log('Check card response:', response.data);
        cardReadingResult.value = TranslateService.translate(response.data.result);

        if (response.data.result === 'OK') {

          // check same accreditation group
          if (cards.find(c => c.accreditationGroup !== response.data.idAccreditationGroup)) {
            cardReadingResult.value = "Karta je z jiné skupiny než již načtené karty";
            return;
          }

          addCard(cardNumber, response.data.idAccreditationGroup, response.data.accreditationGroupKey);
        }
      },
      (error) => {
        cardReadingResult.value = "Chyba při kontrole karty";
        console.error(error);
      }
  );

}

function addCard(cardNumber: number, accreditationGroup: number, accreditationGroupKey: string) {
  cards.push({
    number: cardNumber,
    accreditationGroup: accreditationGroup,
    accreditationGroupKey: accreditationGroupKey
  });
  emit('update:modelValue', {cards: cards})
}

onUpdated(() => {
  cardReaderInput.value.manualFocus();
})
</script>

