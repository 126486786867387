import api from "./support/api";
import {ITicketRequest} from "@/services/contracts/ITicketRequest";
import {ITicketResponse} from "@/services/contracts/ITicketResponse";
import {IVendor} from "@/services/contracts/IVendor";

class TicketService {

    buyAndPrintTickets(data: ITicketRequest) {
        return api.post<ITicketResponse>('ticket/buy-and-print', data);
    }

    printTestTicket(vendor: IVendor ) {
        return api.put<void>('ticket/test/' + vendor.id);
    }

    printTestReceipt(vendor: IVendor) {
        return api.put<void>('ticket/receipt-test/' + vendor.id);
    }

}

export default new TicketService();
