<template>
  <TransitionRoot :show="open" as="template">
    <Dialog as="div" class="relative z-40" @close="open = false">
      <div class="fixed inset-0"/>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
                             enter-from="translate-x-full" enter-to="translate-x-0"
                             leave="transform transition ease-in-out duration-500 sm:duration-700"
                             leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col bg-white shadow-xl">

                  <div class="p-6">
                    <div class="flex items-start justify-between">
                      <h2 id="slide-over-heading" class="text-base font-semibold leading-6 text-gray-900">Nastavení</h2>
                      <div class="ml-3 flex h-7 items-center">
                        <button
                            class="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                            type="button"
                            @click="open = false">
                          <span class="absolute -inset-2.5"/>
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" class="h-6 w-6"/>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Main -->
                  <div class="p-6 text-sm text-gray-500">
                    <div>
                      <div v-show="printerStore.state != PrinterState.OK" class="text-red-500 pb-4 h-10 flex align-middle">
                        <ExclamationTriangleIcon v-show="printerStore.state == PrinterState.WARNING"
                                    class="h-6 w-6 text-orange-500"/>
                        <ExclamationTriangleIconSolid v-show="printerStore.state == PrinterState.ERROR"
                                                 class="h-6 w-6 text-red-500"/>
                        <span class="pl-2 align-middle">{{ printerStore.info }}</span>
                      </div>
                    </div>
                    <div>
                      <p>Uživatel</p>
                      <h3 class="text-xl font-bold text-gray-900">{{ authStore.state?.username }}</h3>
                      <p> {{ userInfo.firstName + ' ' + userInfo.lastName }}</p>
                    </div>
                    <div class="pt-8">
                      <p>Prodejní místo</p>
                      <h3 class="text-xl font-bold text-gray-900 py-2">{{ vendorStore.vendor?.name }}</h3>
                      <MyButton :disabled="!superBoxOffice" @click="openChangeVendorDialog">Změnit prodejní místo
                      </MyButton>
                    </div>
                    <div class="pt-8">
                      <p>Zbývající vstupenky v tiskárně</p>
                      <h3 class="text-xl font-bold text-gray-900 py-2">{{ printerStore.printer.ticketCount }}</h3>
                      <MyButton :disabled="!superBoxOffice" @click="openLoadTicketsDialog">Doplnit vstupenky</MyButton>
                    </div>
                    <div class="pt-8">
                      <p>Test</p>
                      <div class="flex">
                        <MyButton :disabled="printerStore.isNotAvailable" @click="printTestTicket">Vstupenka
                        </MyButton>
                        <MyButton :disabled="printerStore.isNotAvailable" @click="printTestReceipt">Účtenka
                        </MyButton>
                      </div>
                    </div>
                    <div class="pt-20">
                      <MyButton @click="logout">Odhlásit</MyButton>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
      <MessageBox ref="errorMessageBox" :show-cancel-button="false" title="Chyba"/>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from 'vue'
import {Dialog, DialogPanel, TransitionChild, TransitionRoot,} from '@headlessui/vue'
import {ExclamationTriangleIcon, XMarkIcon} from '@heroicons/vue/24/outline'
import {ExclamationTriangleIcon as ExclamationTriangleIconSolid} from '@heroicons/vue/24/solid'
import UserService from "@/services/UserService";

import MyButton from "./MyButton.vue";
import {useVendorStore} from "@/stores/vendor";
import {IUser} from "@/services/contracts/IUser";
import TicketService from "@/services/TicketService";
import MessageBox from "@/components/MessageBox.vue";
import TranslateService from "@/services/TranslateService";
import {usePrinterStore} from "@/stores/printer";
import {useAuthStore} from "@/stores/auth";
import {PrinterState} from "@/services/contracts/PrinterState";

const emit = defineEmits<{
  (e: 'changeVendor'): void,
  (e: 'changeTicketCount'): void
}>();

const open = ref(false)
const errorMessageBox = ref();

const authStore = useAuthStore();
const vendorStore = useVendorStore();

const userInfo = ref(createNoUser());

const printerStore = usePrinterStore();
const superBoxOffice = computed(() => userInfo.value.roles?.includes("superboxoffice"));

function createNoUser(): IUser {
  return {
    id: 0,
    firstName: '',
    lastName: '-',
    roles: []
  }
}

const show = () => {
  console.log('show profile slider');
  open.value = true;
}

function openChangeVendorDialog() {
  open.value = false;
  emit('changeVendor')
}

function openLoadTicketsDialog() {
  open.value = false;
  emit('changeTicketCount')
}

function printTestTicket() {
  const vendor = vendorStore.vendor;
  if (vendor == null) {
    return;
  }
  TicketService.printTestTicket(vendor)
      .then(
          () => printerStore.check()
      )
      .catch(
          (error) => {
            errorMessageBox.value.show('Tisk vstupenky se nezdařil', TranslateService.translate(error?.response?.headers['x-error-message']));
          })
}

function printTestReceipt() {
  const vendor = vendorStore.vendor;
  if (vendor == null) {
    return;
  }
  TicketService.printTestReceipt(vendor)
      .then(
          () => printerStore.check()
      )
      .catch(
          (error) => {
            errorMessageBox.value.show('Tisk účtenky se nezdařil', TranslateService.translate(error?.response?.headers['x-error-message']));
          })
}

function logout() {
  window.location.href = "/logout";
}

onMounted(() => {
  console.log("SettingsSlider - on mounted");
  if (authStore.state === null) {
    userInfo.value = createNoUser();
    console.log("NO USER");
  } else {
    UserService.findById(authStore.state.id).then(
        (response) => {
          console.log(response.data);
          userInfo.value = response.data;
        },
        (error) => {
          console.error((error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString());
        }
    );
  }
})

defineExpose({
  show
})

</script>
