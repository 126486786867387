import AuthService from '@/services/AuthService';

import {computed, ref} from "vue";
import {defineStore} from "pinia";
import {ICredentials} from "@/services/contracts/ICredentials";

export const useAuthStore = defineStore('auth', () => {

    const loggedIn = computed(() => state.value !== null);
    const state = ref(AuthService.getState());

    function login(credentials: ICredentials) {
        return AuthService.login(credentials).then(
            authState => {
                state.value = authState;
                return Promise.resolve(authState);
            },
            error => {
                state.value = null;
                return Promise.reject(error);
            }
        );
    }

    function logout() {
        AuthService.logout();
        state.value = null;
    }

    return {state, loggedIn, login, logout}
})