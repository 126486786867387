<template>
  <div id="page">
    <Disclosure as="nav" class="bg-white shadow-sm sticky top-0 z-10">
      <div class="mx-auto px-8 flex h-16 justify-between">

        <div class="flex">
          <div class="flex flex-shrink-0 items-center">
            <img alt="Box Office" class="block h-8 w-auto" src="/img/logo-small-roll.jpg"/>
          </div>

          <div class="-my-px ml-6 flex space-x-8">
            <router-link v-for="item in navigation" :key="item.name"
                         :class="isSelected(item) ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'"
                         :to="item.path"
                         class="inline-flex items-center border-b-4 px-1 pt-1 text-sm font-medium"
                         @click="selectMenu(item)">
              {{ item.name }}
              <span v-if="item.path===paymentNavigation.path && totalAmount > 0"
                    class="badge">{{ totalAmountFormatted }}</span>
            </router-link>
          </div>
        </div>

        <div class="right-top-corner flex items-center cursor-pointer" @click="showSettings">
          <span title="Přihlášený uživatel">{{ authStore.state?.username }}</span><em>|</em>
          <span title="Prodejní místo">{{ vendorName }}</span><em>|</em>
          <span title="Zbývající vstupenky v tiskárně">{{ printerStore.printer.ticketCount }}</span>

          <div :title="printerStore.info">
            <ExclamationTriangleIcon v-show="printerStore.state == PrinterState.WARNING"
                                     class="bg-tr blink-warning h-6 w-6 text-orange-500"/>
            <ExclamationTriangleIconSolid v-show="printerStore.state == PrinterState.ERROR"
                                          class="blink-error h-6 w-6 text-red-500"/>
          </div>
        </div>

      </div>
    </Disclosure>

    <main id="content">
      <ChatContent :navigation="chatNavigation"/>
      <AccreditationContent :navigation="accreditationNavigation" @moneyChanged="loadMoneyData"/>
      <TicketsContent :navigation="ticketsNavigation" @moneyChanged="loadMoneyData" @printError="printerStore.check"/>
      <PaymentContent :navigation="paymentNavigation" @moneyChanged="setMoneyData"/>
    </main>

  </div>

  <SettingsSlider ref="settingsSlider"
                  @change-vendor="vendorDialog.show()"
                  @change-ticket-count="ticketLoadDialog.show()"/>
  <VendorDialog ref="vendorDialog" @ok="vendorDialogOk"/>
  <TicketLoadDialog ref="ticketLoadDialog" @ok="ticketLoadDialogOk"/>

  <IdleDetector :duration="600" :reminders="[60, 30, 5]" :wait="5"/>
  <VersionChangeDetector/>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from "vue";

import {Disclosure} from '@headlessui/vue'
import {ExclamationTriangleIcon} from '@heroicons/vue/24/outline'
import {ExclamationTriangleIcon as ExclamationTriangleIconSolid} from '@heroicons/vue/24/solid'

import AccreditationContent from "../content/AccreditationContent.vue";
import ChatContent from "../content/ChatContent.vue";
import TicketsContent from "../content/TicketsContent.vue";
import PaymentContent from "../content/PaymentContent.vue";
import IdleDetector from "../components/IdleDetector.vue";
import {useRouter} from "vue-router";
import VendorDialog from "../components/dialog/vendor/VendorDialog.vue";
import {useVendorStore} from "@/stores/vendor";
import SessionService from "@/services/SessionService";
import SettingsSlider from "@/components/SettingsSlider.vue";
import formatService from "@/services/FormatService";
import {usePrinterStore} from "@/stores/printer";
import TicketLoadDialog from "@/components/dialog/ticketload/TicketLoadDialog.vue";
import {PrinterState} from "@/services/contracts/PrinterState";
import {useAuthStore} from "@/stores/auth";
import VersionChangeDetector from "@/components/VersionChangeDetector.vue";
import {useVersionStore} from "@/stores/version";

const router = useRouter();

const versionStore = useVersionStore();
const vendorStore = useVendorStore();
const vendorName = computed(() => {
  let v = vendorStore.vendor;
  if (v === null) {
    return "-"
  }
  return v.name;
})
const printerStore = usePrinterStore();
const authStore = useAuthStore();

const settingsSlider = ref();
const vendorDialog = ref();
const ticketLoadDialog = ref();

export interface INavigation {
  name: string,
  path: string
}

const chatNavigation: INavigation = {name: 'Chat', path: '/chat'};
const accreditationNavigation: INavigation = {name: 'Akreditace', path: '/akreditace'};
const ticketsNavigation: INavigation = {name: 'Vstupenky', path: '/vstupenky'};
const paymentNavigation: INavigation = {name: 'Platba', path: '/platba'};

const navigation: Array<INavigation> = [accreditationNavigation, ticketsNavigation, paymentNavigation];

setInterval(printerStore.check, 60 * 1000);

onMounted(() => {
  versionStore.reloadOnVersionDifference();
  loadMoneyData();
})

function isSelected(item: INavigation) {
  return router.currentRoute.value.path === item.path;
}

function selectMenu(item: INavigation) {
  router.push(item.path);
}

function showSettings() {
  settingsSlider.value.show();
}

function vendorDialogOk() {
  printerStore.check();
  settingsSlider.value.show();
}

function ticketLoadDialogOk() {
  printerStore.check()
  settingsSlider.value.show();
}

const totalAmount = ref(0);

const totalAmountFormatted = computed(() => {
  return formatService.toCurrency(totalAmount.value);
});

function setMoneyData(value: number) {
  printerStore.check();
  totalAmount.value = value;
  console.log(value);
}

function loadMoneyData() {
  printerStore.check();

  if (vendorStore.vendor == null) {
    totalAmount.value = 0;
    return;
  }
  SessionService.findAvailable(vendorStore.vendor)
      .then(
          (response) => {
            let sum = 0;
            response.data.forEach(i => {
              if (!i.exclude) {
                sum += i.price
              }
            });
            totalAmount.value = sum;
          },
          () => {
            totalAmount.value = 0;
          }
      )
}
</script>

<style>
.right-top-corner {
  @apply text-gray-500;
}

.right-top-corner span {
  @apply text-sm px-3;
}

.right-top-corner em {
  @apply text-gray-300;
}

@keyframes blink-warning {
  50% {
    opacity: 60%
  }
}

@keyframes blink-error {
  50% {
    opacity: 25%
  }
}

.blink-warning {
  animation: blink-warning 2000ms infinite;
}

.blink-error {
  animation: blink-error 500ms infinite;
}
</style>