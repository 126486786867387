<template>
  <MyContent :navigation="props.navigation" @loadData="loadData">
    <LoadingIndicator ref="loading"/>

    <div v-if="connectionError" class="text-lg text-red-500 text-center">Chyba připojení</div>
    <div v-if="vendorIsNull" class="text-sm text-gray-500 text-center">Není nastaveno prodejní místo</div>
    <div v-if="!connectionError && !vendorIsNull && !hasItems" class="text-sm text-gray-500 text-center">Nic k zaplacení</div>

    <table v-if="hasItems" class="my-grid">
      <thead>
      <tr>
        <th class="text-right w-3">#</th>
        <th class="text-left">Položka</th>
        <th class="text-right">Částka</th>
        <th class="text-right">Vyloučit</th>
      </tr>
      </thead>
      <tbody>
      <PaymentTableLine v-for="item in items" :key="item.id" :item="item" @excludeChanged="excludeChanged"/>
      </tbody>
      <tfoot>
      <tr>
        <td></td>
        <td>Celková částka</td>
        <td class="text-right">{{ FormatService.toCurrency(total) }}</td>
        <td class="text-right"></td>
      </tr>
      </tfoot>
    </table>

    <div class="flex-button-line">
      <MyButton :disabled="vendorIsNull || !(total > 0)" @click="dialog.show(total)">Zaplatit</MyButton>
      <MyButton :disabled="vendorIsNull || !hasItems" @click="messageBox.show()">Zrušit</MyButton>
    </div>
    <PaymentDialog ref="dialog" @paid="paid"></PaymentDialog>
    <MessageBox ref="messageBox" title="Zrušit platbu?" message="Opravdu chcete zrušit platbu?" @onOk="cancelled"></MessageBox>
  </MyContent>
</template>

<script lang="ts" setup>
import {computed, Ref, ref} from "vue";
import MyButton from "../components/MyButton.vue";
import PaymentDialog from "../components/dialog/payment/PaymentDialog.vue";
import SessionService from "../services/SessionService";
import {useVendorStore} from "@/stores/vendor";
import PaymentTableLine from "../components/PaymentTableLine.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {ISessionChange} from "@/services/contracts/ISessionChange";
import {SessionState} from "@/services/contracts/SessionState";
import {ISessionItem} from "@/services/contracts/ISessionItem";
import {ISessionitemChange} from "@/services/contracts/ISessionItemChange";
import MyContent from "@/content/MyContent.vue";
import {INavigation} from "@/pages/HomePage.vue";
import FormatService from "../services/FormatService";
import MessageBox from "@/components/MessageBox.vue";

const vendorStore = useVendorStore();
const vendorIsNull = computed(() => vendorStore.vendor == null)

const loading = ref();
const dialog = ref();
const messageBox = ref();
const items: Ref<Array<ISessionItem>> = ref([]);
const connectionError = ref(false);

const total = computed(() => {
  let sum = 0;
  items.value.forEach(i => {
    if (!i.exclude) {
      sum += i.price
    }
  });
  return sum;
})

const hasItems = computed(() => {
  return items.value.length > 0;
})

const props = defineProps<{
  navigation: INavigation
}>();

const emit = defineEmits<{
  (e: 'moneyChanged', value: number): void
}>();

function loadData() {
  if (vendorStore.vendor == null) {
    items.value = [];
    return;
  }
  loading.value.show();
  SessionService.findAvailable(vendorStore.vendor)
      .then(
          (response) => {
            items.value = response.data;
            connectionError.value = false;
            console.log("Session items loaded");
          },
          (error) => {
            items.value = [];
            connectionError.value = true;
            console.error(error);
          }
      )
      .finally(() => {
            loading.value.hide();
            emit('moneyChanged', total.value);
          }
      );
}

function excludeChanged(data: ISessionitemChange) {
  console.log("excludeChange  ", data);
  loading.value.show();
  SessionService.toggleItemExclusion(data)
      .then(
          (response) => {
            items.value = response.data;
            connectionError.value = false;
          },
          (error) => {
            items.value = [];
            connectionError.value = true;
            console.error(error);
          }
      )
      .finally(() => {
        loading.value.hide();
        emit('moneyChanged', total.value);
      });
}

function paid(paymentType: string) {

  if (vendorStore.vendor === null) {
    throw 'Vendor is not set!';
  }

  loading.value.show();
  const changeData: ISessionChange = {
    state: SessionState[SessionState.PAID],
    paymentType: paymentType
  };
  SessionService.close(vendorStore.vendor, changeData).finally(loadData);
}

function cancelled() {

  if (vendorStore.vendor === null) {
    throw 'Vendor is not set!';
  }

  loading.value.show();
  const changeData: ISessionChange = {
    state: SessionState[SessionState.CANCELLED],
    paymentType: 'UNKNOWN'
  };
  SessionService.close(vendorStore.vendor, changeData).finally(loadData);
}
</script>
