import api from "./support/api";
import {IScreening} from "@/services/contracts/IScreening";

class ScreeningService {

    findAvailableScreenings() {
        return api.get<Array<IScreening>>('screening/available');
    }

}

export default new ScreeningService();
