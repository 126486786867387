import api from "./support/api";
import {IUser} from "@/services/contracts/IUser";

class UserService {

    findById(id: number) {
        return api.get<IUser>('user/' + id);
    }

}

export default new UserService();
