import vendorService from "../services/VendorService";
import {Ref, ref} from "vue";
import {defineStore} from "pinia";
import {IVendor} from "@/services/contracts/IVendor";

export const useVendorStore = defineStore('vendor', () => {

    const vendor: Ref<IVendor | null> = ref(vendorService.getVendor())

    function changeVendor(newVendor: IVendor | null): void {
        vendor.value = newVendor;
        vendorService.setVendor(newVendor);
    }

    return {vendor, changeVendor}
})