import api from "./support/api";
import {IVendor} from "@/services/contracts/IVendor";
import {IPrinter} from "@/services/contracts/IPrinter";

class PrinterService {

    check(vendor: IVendor) {
        return api.get<IPrinter>('printer/check/' + vendor.id);
    }

    loadTickets(vendor: IVendor, ticketCount: number) {
        return api.put<void>('printer/load-tickets/' + vendor.id + '/' + ticketCount);
    }

}

export default new PrinterService();
