class TranslateService {

    static readonly text: { [key: string]: string } = {
        PRINTER_IS_NOT_ASSIGNED: 'Tiskárna není nakonfigurována',
        PRINTER_IS_NOT_CONNECTED: 'Tiskárna není připojena',
        PRINTER_NOT_AVAILABLE: 'Tiskárna není k dispozici',
        MISSING_TICKET_TEMPLATE: 'Není nastavena šablona vstupenky',
        MISSING_RECEIPT_TEMPLATE: 'Není nastavena šablona účtenky',
        CARD_NOT_FOUND: 'Karta nenalezena',
        CARD_IS_NOT_VALID: 'Karta je neplatná',
        CARD_MISMATCH: 'Karta má více akreditací! VOLEJ ADMINISTRATORA!',
        CARD_IS_NOT_ASSIGNED_TO_ACCREDITATION: 'Karta není připojená k akreditaci',
        CARD_IS_NOT_ACTIVE: 'Karta není aktivní. (Pravděpodobně vydána nová karta)',
        CARD_IS_NOT_IN_VALID_STATE: 'Akreditace není v platném stavu',
        CARD_IS_NOT_VALID_ON_DATE: 'Karta není platná pro tento den',
        CARD_IS_EMPTY_ON_SCREENING_DATE: 'Karta je pro daný den vyčerpána',
        CARD_TYPE_NOT_SUPPORTED_VIA_BOXOFFICE: 'Kartu nelze měnit na pokladně',
        ACCREDITATION_TYPE_HAS_NO_GROUP: 'Akreditační typ nemá skupinu! VOLEJ ADMINISTRATORA!',

        NO_TICKETS: 'Nejsou k dispozici žádné vstupenky',
        SCREENING_INVALID: 'Neplatné představení',
        SCREENING_NOT_AVAILABLE: 'Představení není k dispozici',
        ACCREDITATION_INVALID_CARD: 'Karta akreditace je neplatná',
        ACCREDITATION_GROUP_MISMATCH: 'Různé typy akreditací',
        ACCREDITATION_INVALID_STATE: 'Akreditace není v platném stavu',
        ACCREDITATION_ALREADY_HAS_TICKET: 'Karta již má na projekci vstupenku',
        ACCREDITATION_INVALID_ON_DATE: 'Akreditace neni platná pro tento den',
        ACCREDITATION_EMPTY_ON_DATE: 'Akreditace je pro daný den vyčerpána',

        FPASS: 'Festival pass',
        PRESS: 'Novináři',
        GUEST: 'Hosté',
        EXCLUSIVE: 'Exclusive',
        PERSONAL: "Akreditace",
        REGULAR: "Festival pass",
        DISCOUNT: "Slevněný festival pass",
        AVAILABLE: "Dostupná",
        RESERVED: "Rezervovaná",
        SOLD: "K tisku",
        PRINTING: "V tisku",
        PRINTED: "Vytištěná",
        PRINT_FAILED: "Chyba tisku"
    }

    translate(input: string): string {
        const result = TranslateService.text[input];
        return result == undefined ? input : result;
    }

    partialAccreditationTranslate(input: string): string {
        return input.replace('REGULAR', TranslateService.text['REGULAR'])
            .replace('DISCOUNT', TranslateService.text['DISCOUNT']);
    }

}

export default new TranslateService();
