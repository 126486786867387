import {computed, Ref, ref} from "vue";
import {defineStore} from "pinia";
import {IPrinter} from "@/services/contracts/IPrinter";
import PrinterService from "@/services/PrinterService";
import TranslateService from "@/services/TranslateService";
import {useVendorStore} from "@/stores/vendor";
import {PrinterState} from "@/services/contracts/PrinterState";

export const usePrinterStore = defineStore('printer', () => {

    const isNotAvailable = computed(() => state.value == PrinterState.ERROR);
    const vendorStore = useVendorStore();
    const printer: Ref<IPrinter> = ref(createEmptyPrinter());

    const state = computed(() => {
        if (printer.value.result !== "OK") {
            return PrinterState.ERROR;
        }
        if (printer.value.ticketCount < printer.value.ticketWarningLevel) {
            return PrinterState.WARNING;
        }
        return PrinterState.OK;
    });

    const info = ref("");

    function updatePrinter(newPrinter: IPrinter | null): void {
        if (newPrinter === null) {
            printer.value = createEmptyPrinter();
        } else {
            printer.value = newPrinter;
        }
    }

    function createEmptyPrinter(): IPrinter {
        return {
            result: "NO_PRINTER",
            ticketCount: 0,
            ticketWarningLevel: 0,
            ticketErrorLevel: 0
        }
    }

    function check() {
        console.log("PRINTER STORE - check")
        if (vendorStore.vendor == null) {
            updatePrinter(null);
            info.value = "Není vybráno prodejní místo";
            return;
        }
        PrinterService.check(vendorStore.vendor)
            .then(
                (response) => {
                    console.log("PRINTER STORE: ", response.data);
                    if (response.data.result == "OK") {
                        if (response.data.ticketCount < response.data.ticketWarningLevel) {
                            info.value = "V tiskárně dochází vstupenky";
                        } else {
                            info.value = "";
                        }
                    } else {
                        info.value = TranslateService.translate(response.data.result);
                    }
                    updatePrinter(response.data)
                },
                (error) => {
                    info.value = TranslateService.translate(error?.response?.headers['x-error-message']);
                    updatePrinter(null)
                }
            )
    }


    return {printer, state, info, isNotAvailable, check}
})