<template>
  <MyContent :navigation="props.navigation" @loadData="loadData">
    <LoadingIndicator ref="loading"/>
    <div class="flex-button-line">
      <MyButton :disabled="vendorIsNull" @click="dialog.show()">Poslat zprávu</MyButton>
    </div>
    <table class="my-grid">
      <thead>
      <tr>
        <th class="text-right w-3">Datum a čas</th>
        <th class="text-left">Zpráva</th>
        <th class="text-left">ID</th>
        <th class="text-right">Směr</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item.id">
        <td class="text-right">{{ item.created }}</td>
        <td>{{ item.message }}</td>
        <td>{{ item.id }}</td>
        <td>{{ item.direction }}</td>
      </tr>
      </tbody>
    </table>
    <ChatMessageDialog ref="dialog" @ok="ok" @cancel="cancel"></ChatMessageDialog>
  </MyContent>
</template>

<script lang="ts" setup>
import MyContent from "@/content/MyContent.vue";
import {INavigation} from "@/pages/HomePage.vue";
import {useVendorStore} from "@/stores/vendor";
import {computed, ref, Ref} from "vue";
import ChatService from "@/services/ChatService";
import {IChatMessage} from "@/services/contracts/IChatMessage";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ChatMessageDialog from "@/components/dialog/chat/ChatMessageDialog.vue";
import MyButton from "@/components/MyButton.vue";

const vendorStore = useVendorStore();
const vendorIsNull = computed(() => vendorStore.vendor == null)

const loading = ref();
const dialog = ref();
const items: Ref<Array<IChatMessage>> = ref([]);
const connectionError = ref(false);

const props = defineProps<{
  navigation: INavigation
}>();

function ok() {
  loadData();
}

function cancel() {
  loadData();
}

function loadData() {
  if (vendorStore.vendor == null) {
    items.value = [];
    return;
  }
  loading.value.show();
  ChatService.find(vendorStore.vendor)
      .then(
          (response) => {
            items.value = response.data;
            connectionError.value = false;
          },
          (error) => {
            items.value = [];
            connectionError.value = true;
            console.error(error);
          }
      )
      .finally(() => {
            loading.value.hide();
          }
      );
}
</script>