import api from "./api";
import AuthService from "@/services/AuthService";

const setup = () => {
    api.interceptors.request.use(
        (config) => {
            const token = AuthService.getLocalAccessToken();
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            if (err.config.url !== "auth/login" && err.response) {
                if (err.response.status === 401) { // && store.state.auth.status.loggedIn) {
                    // Access Token was expired - logout
                    console.log('Access token expired, logging out');

                    window.location.href = '/logout';
                }
            }

            return Promise.reject(err);
        }
    );
};

export default setup;