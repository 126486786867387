<template>
  <MyContent :navigation="props.navigation" class="tickets" @loadData="onLoadData">

    <div class="flex items-center">
      <SearchInput ref="searchInput" :query="query" autofocus class="flex-auto" @search="search"/>
    </div>

    <div class="text-sm mt-4 text-gray-500 text-center">{{ hits }}</div>

    <LoadingIndicator ref="loading"/>

    <table v-if="findScreenings.length > 0" class="my-grid mt-4 overflow-auto">
      <thead>
      <tr>
        <th class="text-left" scope="col">Kod</th>
        <th class="text-left" scope="col">Projekce</th>
        <th class="text-left" scope="col">Kino</th>
        <th class="text-left" scope="col">Datum</th>
        <th class="text-left" scope="col">Čas</th>
        <th class="r-group" scope="col">Hosté</th>
        <th class="g-group" scope="col">Novináři</th>
        <th class="b-group" scope="col">Festival passy</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="screening in findScreenings" :key="screening.id">
        <td>
          <button :disabled="!screening.available"
                  class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 ml-2 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  type="button"
                  @click="showPrintTicketDialog(screening)">
            {{ screening.code }}
          </button>
        </td>
        <td>{{ screening.title }}</td>
        <td>{{ screening.theatre }}</td>
        <td>{{ screening.date }}</td>
        <td>{{ screening.time }}</td>
        <td v-if="!screening.available" class="text-center" colspan="3">{{ screening.state }}</td>
        <td v-if="screening.available && screening.collapsed" class="text-center" colspan="3">
          <div :class="(authStore.state?.username == 'sussmannova') ? 'rainbow-bg' : ''"
               class="bg-gray-200 w-full h-full rounded border border-gray-400 py-1.5 text-black">
            {{ screening.red + screening.green + screening.blue }}
          </div>
        </td>
        <td v-if="screening.available && !screening.collapsed" class="r-group">{{ screening.red }}</td>
        <td v-if="screening.available && !screening.collapsed" class="g-group">{{ screening.green }}</td>
        <td v-if="screening.available && !screening.collapsed" class="b-group">{{ screening.blue }}</td>
      </tr>
      </tbody>
    </table>

    <TicketPrintDialog ref="ticketPrintDialog" @print="onPrintCompleted" @error="onPrintError"/>
    <MessageBox ref="messageBox" :show-cancel-button=false></MessageBox>
  </MyContent>
</template>

<script lang="ts" setup>
import MiniSearch from 'minisearch'
import SearchInput from "../components/SearchInput.vue";
import {computed, Ref, ref} from "vue";
import TicketPrintDialog from "../components/dialog/ticketprint/TicketPrintDialog.vue";
import ScreeningService from "../services/ScreeningService";
import MessageBox from "../components/MessageBox.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {IScreening} from "@/services/contracts/IScreening";
import {ITicketResponse} from "@/services/contracts/ITicketResponse";
import MyContent from "@/content/MyContent.vue";
import {INavigation} from "@/pages/HomePage.vue";
import translateService from "@/services/TranslateService";
import {useAuthStore} from "@/stores/auth";

const ticketPrintDialog = ref();
const messageBox = ref();
const loading = ref();
const query = ref('');
const searchInput = ref();
const screenings: Ref<Array<IScreening>> = ref([]);

const authStore = useAuthStore();

const props = defineProps<{
  navigation: INavigation
}>();

const emit = defineEmits<{
  (e: 'moneyChanged'): void,
  (e: 'printError'): void
}>();

const miniSearch = new MiniSearch({
  fields: ['code', 'title', 'theatre', 'date', 'time'], // fields to index for full-text search
  storeFields: ['id'] // fields to return with search results
})

const hits = computed(() => {
  const len = findScreenings.value.length;
  switch (len) {
    case 1:
      return '1 výsledek'
    case 2:
    case 3:
    case 4:
      return len + ' výsledky'
    default:
      return len + ' výsledků'
  }
});

const findScreenings = computed(() => {
  if (query.value === '') {
    return screenings.value;
  }

  const ids = miniSearch.search(query.value, {
    boost: {code: 4},
    prefix: true,
    combineWith: 'AND'
  });

  return screenings.value.filter(i => ids.find(x => x.id === i.id));
});

function onLoadData() {
  searchInput.value.focusInput();
  loadData();
}

function unaccent(s: string): string {
  return s.normalize("NFD").replace(/\p{Diacritic}/gu, "");
}

function loadData() {
  loading.value.show();
  ScreeningService.findAvailableScreenings()
      .then(
          (response) => {
            screenings.value = response.data;

            const norm = response.data.map(e => ({
              id: e.id,
              title: unaccent(e.title),
              state: e.state,
              theatre: unaccent(e.theatre),
              date: e.date,
              time: e.time,
              code: e.code,
              blue: e.blue,
              green: e.green,
              red: e.red,
              available: e.available,
              collapsed: e.collapsed
            }));

            miniSearch.removeAll();
            miniSearch.addAll(norm);
          },
          (error) => {
            screenings.value = [];
            console.error(error);
          }
      )
      .finally(() => loading.value.hide());
}

function search(_query: string) {
  query.value = unaccent(_query);
}

function showPrintTicketDialog(screening: IScreening) {
  ticketPrintDialog.value.show(screening);
}

function onPrintCompleted(response: ITicketResponse) {
  if (response.result !== "OK") {
    messageBox.value.show("Chyba", translateService.translate(response.result));
    emit('printError');
  }
  loadData();
  emit('moneyChanged');
}

function onPrintError(error: string) {
  messageBox.value.show('Tisk vstupenky se nezdařil', error);
  emit('printError');
}

</script>

<style>
.rainbow-bg {
  background: linear-gradient(-45deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%);
}

</style>
