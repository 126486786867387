<template>
  <div v-show="visible">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {INavigation} from "@/pages/HomePage.vue";

const route = useRoute();
const visible = ref(false);

const props = defineProps<{
  navigation: INavigation
}>();
const emit = defineEmits<{
  loadData: []
}>();

watch(() => route.path, () => {
  console.log('onWatch');
  reload();
});

onMounted(() => {
  console.log('onMount');
  reload();
})

function reload() {
  visible.value = route.path === props.navigation.path;
  if (visible.value) {
    console.log('loadData');
    emit('loadData');
  }
}
</script>
