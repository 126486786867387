<template>
  <NotificationPopup ref="idleNotification" :auto-hide="false"/>
</template>

<script lang="ts" setup>
import {nextTick, onBeforeUnmount, onMounted, ref} from "vue";
import NotificationPopup from "@/components/NotificationPopup.vue";

export interface IdleDetectorProps {
  /**
   * Logout after the specified time in seconds
   */
  duration: number,
  events?: Array<string>,
  loop?: boolean,
  /**
   * Display reminder at the specified number of seconds before logout
   */
  reminders?: Array<number>,
  wait?: number
}

const props = withDefaults(defineProps<IdleDetectorProps>(), {
  events: () => ["mousemove", "keypress"],
  loop: true,
  reminders: () => [],
  wait: 0
});

const emit = defineEmits<{ idle: [], remind: [diff: number], reset: [] }>();

const idleNotification = ref();

let display = '';
let timer: number | undefined = undefined;
let start = 0;
let counter: number | undefined = undefined;
let diff = 0;
let minutes = '';
let seconds = '';

onMounted(() => {
  setTimeout(() => {
    start = Date.now();
    setDisplay();
    nextTick(() => {
      setTimer();
      for (let i = props.events.length - 1; i >= 0; i -= 1) {
        window.addEventListener(props.events[i], clearTimer);
      }
    });
  }, 0);
});

onBeforeUnmount(() => {
  clearInterval(timer);
  clearInterval(counter);
  for (let i = props.events.length - 1; i >= 0; i -= 1) {
    window.removeEventListener(props.events[i], clearTimer);
  }
});

function setDisplay() {
  // seconds since start
  diff = props.duration - (((Date.now() - start) / 1000) | 0);
  if (diff < 0 && !props.loop) {
    return;
  }
  shouldRemind();

  // bitwise OR to handle parseInt
  const minute = (diff / 60) | 0;
  const second = diff % 60 | 0;

  minutes = `${minute < 10 ? "0" + minute : minute}`;
  seconds = `${second < 10 ? "0" + second : second}`;

  display = `${minutes}:${seconds}`;
}

function shouldRemind() {
  if (props.reminders.length > 0) {
    if (props.reminders.includes(diff)) {
      remind();
    }
  }
}

function countdown() {
  setDisplay();

  if (diff <= 0) {
    clearTimer(undefined, props.loop);
  }
}

function idle() {
  emit('idle');
  window.location.href = '/logout';
}

function remind() {
  idleNotification.value.show('Detektor nečinnosti', 'Odhlášení proběhne za ' + display + ' !');
  emit('remind', diff);
}

function reset() {
  idleNotification.value.hide();
  emit('reset');
}

function setTimer() {
  timer = window.setInterval(idle, props.duration * 1000);
  counter = window.setInterval(countdown, 1000);
}

function clearTimer(event: Event | undefined, loop = true) {
  window.clearInterval(timer);
  window.clearInterval(counter);
  reset();
  if (loop) {
    start = Date.now();
    diff = 0;
    setDisplay();
    setTimer();
  }
}

</script>
