<template>
  <div aria-live="assertive"
       class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">

      <transition enter-active-class="transform ease-out duration-300 transition"
                  enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                  leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                  leave-to-class="opacity-0">
        <div v-if="show"
             class="pointer-events-auto w-full max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0 pt-0.5">
                <InformationCircleIcon/>
              </div>
              <div class="ml-3 w-0 flex-1">
                <p class="text-sm font-medium text-gray-900">Nová verze aplikace</p>
                <p class="mt-1 text-sm text-gray-500">Prosím obnovte aplikaci v prohlížeči</p>
                <div class="mt-4 flex">
                  <button class="inline-flex items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" type="button"
                          @click="versionStore.reloadOnVersionDifference()">
                    Obnovit
                  </button>
                </div>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" type="button"
                        @click="show = false">
                  <span class="sr-only">Zavřít</span>
                  <XMarkIcon aria-hidden="true" class="h-5 w-5"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {ref, watch} from "vue"
import {useVersionStore} from "@/stores/version"
import {InformationCircleIcon, XMarkIcon} from '@heroicons/vue/20/solid'

const show = ref(false)

const versionStore = useVersionStore();

watch(() => versionStore.versionChanged, () => {
  console.log('Version changed');
  show.value = true;
})

</script>
