<template>
  <MyContent :navigation="props.navigation" @loadData="loadData">
    <LoadingIndicator ref="loading"/>

    <div class="flex-button-line">
      <MyButton @click="cardReaderDialog.show()">Načíst kartu</MyButton>
    </div>

    <div v-if="card.id != 0">

      <h1 class="text-center font-semibold text-gray-900 mt-3">{{ cardHeadline }}</h1>

      <div class="p-10">
        <ul class="flex gap-6 justify-center" role="list">
          <template v-for="date in card.dates" :key="date.id">
            <DateCard :item="date" @onchange="onchange"/>
          </template>
        </ul>
        <div class="mx-auto pt-3 flex justify-end max-w-[950px]">
          <MyButton :disabled="card.cardType == 'PERSONAL'" @click="checkAllDays">Vybrat všechny dny</MyButton>
        </div>
      </div>

      <div class="text-center">
        <p>{{ price }}</p>
      </div>

    </div>

    <div class="flex-button-line">
      <MyButton :disabled="vendorIsNull || !accreditationChanged" @click="showActivationMessageBox">Aktivovat kartu
      </MyButton>
      <MyButton :disabled="vendorIsNull || !accreditationChanged" @click="cancelActivation">Storno</MyButton>
    </div>

    <h1 class="text-center font-semibold text-gray-900 pt-10">Rezervované vstupenky k tisku</h1>

    <table class="my-grid" v-if="card.tickets.length > 0">
      <thead>
      <tr>
        <th class="text-left">Kód</th>
        <th class="text-left">Projekce</th>
        <th class="text-left">Kino</th>
        <th class="text-left">Datum</th>
        <th class="text-left">Čas</th>
        <th class="text-left">Stav</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="ticket in card.tickets" :key="ticket.id">
        <td>{{ ticket.screeningCode }}</td>
        <td>{{ ticket.title }}</td>
        <td>{{ ticket.theatre }}</td>
        <td>{{ ticket.date }}</td>
        <td>{{ ticket.time }}</td>
        <td>{{ translateService.translate(ticket.state) }}</td>
      </tr>
      </tbody>
    </table>
    <div v-if="card.tickets.length === 0" class="text-sm text-gray-500 text-center pt-4">Žádné vstupenky k
      vytištění...
    </div>
    <div class="flex-button-line">
      <MyButton :disabled="printTicketsDisabled" @click="printTickets">
        Vytisknout vstupenky
      </MyButton>
    </div>

    <MessageBox ref="activateCardMessageBox" title="Aktivace karty" :show-question-mark="true" @onOk="activateCard"/>
    <MessageBox ref="errorMessageBox" :show-cancel-button="false" title="Chyba"/>
    <CardReaderDialog ref="cardReaderDialog" @readCard="readCard"/>
  </MyContent>
</template>

<script lang="ts" setup>
import {computed, Ref, ref} from "vue";
import MyButton from "../components/MyButton.vue";
import DateCard from "../components/DateCard.vue";
import MessageBox from "../components/MessageBox.vue";
import CardReaderDialog from "../components/CardReaderDialog.vue";
import AccreditationService from "../services/AccreditationService";
import {useVendorStore} from "@/stores/vendor";
import {IAccreditation} from "@/services/contracts/IAccreditation";
import {IAccreditationChange} from "@/services/contracts/IAccreditationChange";
import {IAccreditationPrice} from "@/services/contracts/IAccreditationPrice";
import {IAccreditationDate} from "@/services/contracts/IAccreditationDate";
import MyContent from "@/content/MyContent.vue";
import {INavigation} from "@/pages/HomePage.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import TranslateService from "@/services/TranslateService";
import translateService from "@/services/TranslateService";
import FormatService from "@/services/FormatService";
import {usePrinterStore} from "@/stores/printer";

const vendorStore = useVendorStore();
const vendorIsNull = computed(() => vendorStore.vendor == null)

const errorMessageBox = ref();
const activateCardMessageBox = ref()
const cardReaderDialog = ref()
const loading = ref();

const printTicketsClicked = ref(false);
const card: Ref<IAccreditation> = ref(createEmptyCard());
const cardHeadline = computed(() => {
  return TranslateService.translate(card.value.cardType) + " #" + card.value.id
})
const printTicketsDisabled = computed(() => {
  if (printTicketsClicked.value || usePrinterStore().isNotAvailable) {
    return true;
  }
  return !card.value.tickets.some(t => t.state == 'SOLD');
})

const props = defineProps<{
  navigation: INavigation
}>();

const emit = defineEmits<{
  (e: 'moneyChanged'): void
}>();

const price = computed(() => {
  const daysCount = changedDaysCount();
  if (daysCount === 0) {
    return FormatService.toCurrency(0);
  }
  const p = changedDaysPrice(daysCount);
  if (p === undefined) {
    let text = "Pro " + daysCount + " "
    if (daysCount === 1) {
      text += "den"
    } else if (daysCount < 5) {
      text += "dny"
    } else {
      text += "dnů"
    }
    return text + " neznám cenu - neprodám"
  }
  return FormatService.toCurrency(p.price);
})

const accreditationChanged = computed(() => {
  const daysCount = changedDaysCount()
  if (daysCount === 0) {
    return false
  }
  return changedDaysPrice(daysCount) !== undefined
})

function loadData() {
  card.value = createEmptyCard();
}

function readCard(number: number): void {
  console.info('card number', number);
  loading.value.show();
  AccreditationService.findById(number)
      .then(
          (response) => {
            card.value = response.data;
            console.log(response.data);
            console.log("card loaded");
          },
          (error) => {
            card.value = createEmptyCard();
            console.error(error);
            errorMessageBox.value.show('Chyba načtení karty',
                'Karta ' + number + ' má problem: ' + TranslateService.translate(error?.response?.headers['x-error-message']));
          }
      ).finally(
      () => {
        loading.value.hide()
      }
  );
}

function activateCard() {

  if (vendorStore.vendor === null) {
    throw 'Vendor is not set!';
  }

  const accreditationChange: IAccreditationChange = {
    idVendor: vendorStore.vendor.id,
    addedDateIds: card.value.dates.filter((item) => item.changed).map((item) => item.id)
  };

  AccreditationService.change(card.value.id, accreditationChange)
      .then(
          (response) => {
            console.log(response);
            emit("moneyChanged");
          },
          (error) => {
            errorMessageBox.value.show('Aktivace se nezdařila',
                TranslateService.translate(error?.response?.headers['x-error-message']));
          }
      )
      .finally(() => card.value = createEmptyCard());
}

function createEmptyCard(): IAccreditation {
  return {
    id: 0,
    cardType: '',
    accreditationTypeIc: 0,
    accreditationState: '',
    dates: [],
    prices: [],
    tickets: []
  };
}

function checkAllDays() {
  card.value.dates.forEach(checkDate);
}

function checkDate(item: IAccreditationDate) {
  if (item.editable && !item.checked) {
    item.checked = true;
    item.changed = true;
    onchange(item);
  }
}

function onchange(item: IAccreditationDate) {
  console.log(item);
  if (item.checked) {
    item.capacity = card.value.accreditationTypeIc + "/" + card.value.accreditationTypeIc;
  } else {
    item.capacity = "0/0";
  }
}

function changedDaysCount(): number {
  return card.value.dates.reduce((sum, item) => item.changed ? ++sum : sum, 0);
}

function changedDaysPrice(daysCount: number): IAccreditationPrice | undefined {
  return card.value.prices.find((item) => item.days === daysCount);
}

function showActivationMessageBox() {
  activateCardMessageBox.value.show('Aktivace karty', 'Chceš aktivovat kartu #' + card.value.id + '?');
}

function cancelActivation() {
  card.value = createEmptyCard();
}

function printTickets() {
  if (vendorStore.vendor === null) {
    throw 'Vendor is not set!';
  }
  loading.value.show();
  printTicketsClicked.value = true;
  AccreditationService.printTickets(card.value.id, vendorStore.vendor.id)
      .then(
          (response) => {
            card.value = response.data;
          },
          (error) => {
            errorMessageBox.value.show('Tisk se nezdařil', TranslateService.translate(error?.response?.headers['x-error-message']));
            card.value = createEmptyCard();
          }
      )
      .finally(() => {
        printTicketsClicked.value = false
        loading.value.hide()
      })
}
</script>


