<template>
  <div v-show="visible" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" role="status">
    <svg height="57" stroke="#6366f1" viewBox="0 0 57 57" width="57" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fill-rule="evenodd">
        <g stroke-width="2" transform="translate(1 1)">
          <circle cx="5" cy="50" r="5">
            <animate attributeName="cy" begin="0s" calcMode="linear" dur="2.2s" repeatCount="indefinite"
                     values="50;5;50;50"/>
            <animate attributeName="cx" begin="0s" calcMode="linear" dur="2.2s" repeatCount="indefinite"
                     values="5;27;49;5"/>
          </circle>
          <circle cx="27" cy="5" r="5">
            <animate attributeName="cy" begin="0s" calcMode="linear" dur="2.2s" from="5" repeatCount="indefinite" to="5"
                     values="5;50;50;5"/>
            <animate attributeName="cx" begin="0s" calcMode="linear" dur="2.2s" from="27" repeatCount="indefinite"
                     to="27"
                     values="27;49;5;27"/>
          </circle>
          <circle cx="49" cy="50" r="5">
            <animate attributeName="cy" begin="0s" calcMode="linear" dur="2.2s" repeatCount="indefinite"
                     values="50;50;5;50"/>
            <animate attributeName="cx" begin="0s" calcMode="linear" dur="2.2s" from="49" repeatCount="indefinite"
                     to="49"
                     values="49;5;27;49"/>
          </circle>
        </g>
      </g>
    </svg>
    <!--    <span class="sr-only">Loading...</span>-->
  </div>
</template>

<script setup lang="ts">

import {ref} from "vue";

defineExpose({show, hide});

const visible = ref(false);
const showRequested = ref(false);

function show() {
  showRequested.value = true;
  setTimeout(() => {
    if (showRequested.value) {
      visible.value = true;
    }
  }, 200)
}

function hide() {
  showRequested.value = false;
  visible.value = false;
}

</script>
