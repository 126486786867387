import api from "@/services/support/api";
import {ICredentials} from "@/services/contracts/ICredentials";
import {IAuthState} from "@/services/contracts/IAuthState";

class AuthService {

    private static readonly KEY: string = "state";

    login(credentials: ICredentials) {
        return api.post<IAuthState>('auth/login', credentials)
            .then(
                (response) => {
                    console.log('AuthService.login', credentials.username, response);
                    if (response.data.accessToken) {
                        this.setState(response.data);
                    }
                    return response.data;
                });
    }

    logout() {
        console.info('AuthService.logout', this.getState());
        api.post('auth/logout').finally(() => {
        });
        this.removeState();
    }

    getLocalAccessToken(): string | null {
        const state = this.getState();
        if (state === null) {
            return null;
        }
        return state.accessToken;
    }

    updateLocalAccessToken(accessToken: string): void {
        const state = this.getState();
        if (state != null) {
            state.accessToken = accessToken;
            sessionStorage.setItem(AuthService.KEY, JSON.stringify(state));
        }
    }

    getState(): IAuthState | null {
        const item = sessionStorage.getItem(AuthService.KEY);
        if (item === null) {
            return null;
        }
        return JSON.parse(item);
    }

    setState(state: IAuthState): void {
        sessionStorage.setItem(AuthService.KEY, JSON.stringify(state));
    }

    removeState(): void {
        sessionStorage.removeItem(AuthService.KEY);
    }
}

export default new AuthService();
