<template>
  <MyDialog :disabled="true" ref="dialog" title="Doplnění vstupenek do tiskárny" @cancel="cancel" @ok="ok">

    <MyInput ref="input"
             v-model.number="count"
             type="number"
             label="Počet vstupenek"
             name="ticketCount"
             @update:modelValue="checkSubmittable"/>

  </MyDialog>
</template>

<script lang="ts" setup>
import {ref} from 'vue'
import MyDialog from "../MyDialog.vue";
import MyInput from "@/components/MyInput.vue";
import printerService from "@/services/PrinterService";
import {useVendorStore} from "@/stores/vendor";

const emit = defineEmits<{ ok: [], cancel: [] }>()

const dialog = ref()
const input = ref();

const count = ref(0);
const vendorStore = useVendorStore();

function checkSubmittable() {
  input.value.setValid(0 <= count.value);

  if (vendorStore.vendor == null) {
    dialog.value.setSubmittable(false);
    return;
  }

  dialog.value.setSubmittable(0 < count.value);
}

function ok() {
  if (vendorStore.vendor === null) {
    throw 'Vendor is not set!';
  }

  printerService.loadTickets(vendorStore.vendor, count.value)
      .catch(() => console.log('NECO SE NEPOVEDLO'))
      .finally(() => emit('ok'))
}

function cancel() {
  emit('cancel')
}

function show() {
  count.value = 0;
  dialog.value.setSubmittable(false);
  dialog.value.show()
}

defineExpose({
  show
})
</script>

<style>

</style>