<template>
  <div class="login-page">
    <div class="mx-auto w-full max-w-[480px]">
      <div class="bg-white px-12 py-12 shadow rounded-lg">
        <img alt="Box Office" src="/img/logo-medium.jpg" title="Box Office"/>

        <div class="vendor">
          {{ vendorName }}
        </div>
        <form class="space-y-6" @submit.prevent="handleLogin">
          <div class="mt-2">
            <input id="username" v-model="credentials.username" autocomplete="off" autofocus
                   name="username" placeholder="Uživatel" :required="true"
                   type="text"/>
          </div>

          <div class="mt-2">
            <input id="password" v-model="credentials.password" autocomplete="off"
                   name="password"
                   placeholder="Heslo" :required="true"
                   type="password"/>
          </div>

          <div class="login-error">{{ message }}</div>

          <div>
            <button type="submit" :disabled="loginInProgress">Přihlásit</button>
          </div>
        </form>

        <div class="version">{{ version }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useRouter} from 'vue-router';
import {computed, onMounted, ref} from "vue";
import versionService from "@/services/VersionService";
import {useAuthStore} from "@/stores/auth";
import {useVendorStore} from "@/stores/vendor";

const loginInProgress = ref(false)
const authStore = useAuthStore();
const router = useRouter();
const loading = ref(false);
const message = ref('');
const credentials = ref({username: '', password: ''});

const version = ref('');
const vendorStore = useVendorStore();
const vendorName = computed(() => {
  let v = vendorStore.vendor;
  if (v === null) {
    return ""
  }
  return v.name;
})

if (authStore.loggedIn) {
  router.push("/");
}

onMounted(() => {
  versionService.findVersion().then(
      (response) => {
        version.value = response.data;
      },
      () => {
        version.value = '?';
      });
});

function handleLogin() {
  loading.value = true;
  loginInProgress.value = true;
  authStore.login(credentials.value)
      .then(
          (data) => {

            if (data.accessToken) {
              console.log('handleLogin success');
              router.push("/");
            } else {
              loginFailed(data)
            }
          },
          (error) => {
            loginFailed(error)
          })
      .finally(() => loginInProgress.value = false);
}

function loginFailed(data: any) {
  console.warn('Login failed', data);

  credentials.value.username = "";
  credentials.value.password = "";
  loading.value = false;
  console.log(data);
  if (data && data.response && data.response.status === 401) {
    message.value = "Neplatné uživatelské jméno nebo heslo";
  } else {
    message.value = "Ověřovací server je nedostupný";
  }
}

</script>

<style scoped>

.login-page {
  @apply flex min-h-full flex-1 flex-col justify-center
}

input {
  @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
}

button {
  @apply flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
}

img {
  @apply mx-auto w-auto mb-6 rounded-md
}

.vendor {
  @apply mb-4 text-center text-sm
}

.version {
  @apply mt-4 text-center text-sm text-gray-500
}

.login-error {
  @apply text-red-600 text-center
}

</style>
