<template>
  <div class="preloader-scan">
    <ul>
      <li style="width: 3px;"></li>
      <li style="width: 2px;"></li>
      <li style="width: 4px;"></li>
      <li style="width: 3px;"></li>
      <li style="width: 3px;"></li>
      <li style="width: 1px;"></li>
      <li style="width: 3px;"></li>
      <li style="width: 5px;"></li>
      <li style="width: 3px;"></li>
      <li style="width: 1px;"></li>
      <li style="width: 4px;"></li>
      <li style="width: 3px;"></li>
      <li style="width: 2px;"></li>
      <li style="width: 1px;"></li>
      <li style="width: 3px;"></li>
      <li style="width: 1px;"></li>
      <li style="width: 3px;"></li>
      <li style="width: 5px;"></li>
      <li style="width: 3px;"></li>
      <li style="width: 1px;"></li>
    </ul>
    <div class="diode" v-show="laserOn">
      <div class="laser"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from "vue";

const laserOn = ref(true);

function showLaser() {
  laserOn.value = true;
}

function hideLaser() {
  laserOn.value = false;
}

defineExpose({
  showLaser,
  hideLaser
})
</script>

<style>
.preloader-scan {
  position: relative;
  left: 0;
  right: 0;
  max-width: 200px;
  display: table;
  margin: 0 auto;
  height: 100px;
  text-align: center;
}

.preloader-scan ul {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  list-style-type: none;
  text-align: center;
}

.preloader-scan li {
  display: inline-block;
  width: 2px;
  height: 50px;
  background-color: #444;
  margin: 2px;
}

.preloader-scan .laser {
  width: 150%;
  margin-left: -125%;
  background-color: tomato;
  height: 1px;
  position: absolute;
  top: 30%;
  z-index: 2;
  box-shadow: 0 0 4px red;
  animation: scanning 2s infinite;
}

.preloader-scan .diode {
  animation: beam .01s infinite;
}

body {
  height: 100%;
}

@keyframes beam {
  50% {
    opacity: .75;
  }
}

@keyframes scanning {
  50% {
    transform: translateY(30px);
  }
}
</style>
