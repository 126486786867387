<template>
  <div>
    <label :for="name" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
    <div class="mt-2">
      <textarea
          rows="4"
          :name="name"
          :value="modelValue"
          @input="updateModelValue"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
    </div>
  </div>
</template>

<script setup lang="ts">

defineProps<{
  modelValue: any,
  name: string,
  label: string
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: string): string;
}>();

const updateModelValue = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).value)
};

</script>

<style scoped>

</style>