<template>
  <MyDialog ref="dialog"
            @ok="paid"
            @cancel="cancel"
            ok-button-text="Zaplaceno"
            title="Platba">

    <div class="grid grid-cols-2 mt-4">
      <fieldset>
        <div class="space-y-4 pb-2">
          <h2 class="font-medium text-gray-900 mt-4">Způsob úhrady</h2>
          <div v-for="type in paymentTypes" :key="type.key" class="flex items-center gap-x-3">

            <input :id="type.key"
                   v-model="paymentType"
                   class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                   type="radio"
                   :value="type.key"/>
            <label class="block text-sm font-medium leading-6 text-gray-900"
                   :for="type.key">{{ type.name }}</label>
          </div>
        </div>
      </fieldset>
      <div class="border-l border-l-gray-300">
        <h2 class="font-medium text-gray-900 mt-4">&nbsp;</h2>
        <table class="text-sm w-full">
          <tr>
            <td class="pl-4 w-1/2">Částka</td>
            <td class="text-right px-3">{{ total }}</td>
          </tr>
          <tr v-if="paymentType === PaymentType[PaymentType.CASH]">
            <td class="pl-4 w-1/2">Přijato</td>
            <td class="text-right"><input
                class="block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 text-right"
                v-model.number="income"/></td>
          </tr>
          <tr v-if="paymentType === PaymentType[PaymentType.CASH]">
            <td class="pl-4 w-1/2">Vrátit</td>
            <td class="text-right px-3">{{ returnModel }}</td>
          </tr>
        </table>
      </div>
    </div>

  </MyDialog>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue'
import MyDialog from "../MyDialog.vue";
import {PaymentType} from "@/services/contracts/PaymentType";
import {usePrinterStore} from "@/stores/printer";

const emit = defineEmits<{
  paid: [paymentType: string],
  cancel: []
}>();

const paymentTypes = [
//   {key: 'UNKNOWN', name: 'UNKNOWN'},
  {key: PaymentType[PaymentType.CARD], name: 'Platební karta'},
  {key: PaymentType[PaymentType.CASH], name: 'Hotovost'}
]

const paymentType = ref(PaymentType[PaymentType.CARD]);
const total = ref(0);
const income = ref(0);

const dialog = ref();

const returnModel = computed(() => {
  if (total.value > income.value) {
    return 'To je málo!';
  }
  return income.value - total.value
});

function show(_total: number) {
  paymentType.value = PaymentType[PaymentType.CARD];
  total.value = _total;
  income.value = 0;
  dialog.value.setSubmittable(!usePrinterStore().isNotAvailable);
  dialog.value.show();
}

function paid() {
  console.log('PAID');
  emit('paid', paymentType.value);
}

function cancel() {
  emit('cancel');
}

defineExpose({
  show
})
</script>

<style>

</style>