<template>
  <MyDialog :disabled="true" ref="dialog" title="Odeslat zprávu operátorovi" @cancel="cancel" @ok="ok">

    <MyTextarea ref="textarea"
                v-model="text"
                label="Zpráva"
                name="text"
                @update:modelValue="checkSubmittable"/>

  </MyDialog>
</template>

<script lang="ts" setup>
import {ref} from 'vue'
import MyDialog from "../MyDialog.vue";
import {useVendorStore} from "@/stores/vendor";
import MyTextarea from "@/components/MyTextarea.vue";
import ChatService from "@/services/ChatService";

const emit = defineEmits<{ ok: [], cancel: [] }>()

const dialog = ref()
const textarea = ref();

const text = ref('');
const vendorStore = useVendorStore();

function checkSubmittable() {

  if (vendorStore.vendor == null) {
    dialog.value.setSubmittable(false);
    return;
  }

  dialog.value.setSubmittable(text.value.length > 0);
}

function ok() {
  if (vendorStore.vendor === null) {
    throw 'Vendor is not set!';
  }

  const data = {message: text.value};
  console.log(data);
  ChatService.addMessage(vendorStore.vendor, data)
      .catch(() => console.log('NECO SE NEPOVEDLO'))
      .finally(() => emit('ok'))
}

function cancel() {
  emit('cancel')
}

function show() {
  text.value = '';
  dialog.value.setSubmittable(false);
  dialog.value.show()
}

defineExpose({
  show
})
</script>

<style>

</style>