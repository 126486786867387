import {createApp} from 'vue'
import App from './App.vue'
import router from "./router";
import './index.css';
import setupInterceptor from './services/support/interceptor';
import {createPinia} from "pinia";

setupInterceptor();

createApp(App)
    .use(router)
    .use(createPinia())
    .mount('#app');
