<template>
  <div style="height: 400px">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex" aria-label="Tabs">
        <a v-for="tab in tabs" :key="tab.name"
           @click="selectItem(tab.key)"
           href="#"
           :class="isSelected(tab.key) ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'"
           class="w-1/2 grow border-b-2 py-4 px-1 text-center text-sm font-medium"
        >{{ tab.name }}</a>
      </nav>
    </div>
    <AccreditationPart v-show="isSelected(RequestMethod[RequestMethod.ACCREDITATION])" v-model="accreditationPartModel"
                       :screening="props.screening"></AccreditationPart>
    <MoneyPart v-show="isSelected(RequestMethod[RequestMethod.MONEY])" v-model="moneyPartModel"></MoneyPart>
  </div>
</template>

<script lang="ts" setup>
import MoneyPart from "./MoneyPart.vue";
import AccreditationPart from "./AccreditationPart.vue";
import {computed} from "vue";
import {ITicketPrintFormData} from "@/services/contracts/ITicketPrintFormData";
import {IScreening} from "@/services/contracts/IScreening";
import {ITicketPrintAccreditationPart} from "@/services/contracts/ITicketPrintAccreditationPart";
import {ITicketCount} from "@/services/contracts/ITicketCount";
import {RequestMethod} from "@/services/contracts/RequestMethod";

const props = defineProps<{
  modelValue: ITicketPrintFormData,
  screening: IScreening
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: ITicketPrintFormData): void
}>();

const accreditationPartModel = computed({
  get() {
    return props.modelValue.accreditation;
  },
  set(value: ITicketPrintAccreditationPart) {
    const emitData = {
      accreditation: value,
      money: props.modelValue.money,
      method: props.modelValue.method
    }
    emit('update:modelValue', emitData)
  }
})

const moneyPartModel = computed({
  get() {
    return props.modelValue.money;
  },
  set(value: ITicketCount) {
    console.log(value);
    const emitData = {
      accreditation: props.modelValue.accreditation,
      money: value,
      method: props.modelValue.method
    }
    emit('update:modelValue', emitData)
  }
})

const selected = computed({
  get() {
    return props.modelValue.method;
  },
  set(value: string) {
    const emitData = {
      accreditation: props.modelValue.accreditation,
      money: props.modelValue.money,
      method: value
    }
    emit('update:modelValue', emitData)
  }
})

const tabs = [
  {key: RequestMethod[RequestMethod.ACCREDITATION], name: 'Akreditace'},
  {key: RequestMethod[RequestMethod.MONEY], name: 'Peníze'}
]

function selectItem(key: string) {
  selected.value = key;
}

function isSelected(key: string) {
  return selected.value === key;
}

</script>