<template>
  <MyDialog ref="dialog" :show-ok-button="false" title="Načtení akreditační karty">
    <CardReaderInput @enterKeyUp="readCard"/>
  </MyDialog>
</template>

<script lang="ts" setup>
import CardReaderInput from "./CardReaderInput.vue";
import MyDialog from "@/components/dialog/MyDialog.vue";
import {ref} from "vue";

const dialog = ref();
const emit = defineEmits<{ readCard: [cardNumber: number] }>();

function readCard(value: string) {
  console.log('readerdialog readCard', value);
  dialog.value.hide();
  emit('readCard', +value);
}

function show() {
  dialog.value.show();
}

defineExpose({
  show
})
</script>
