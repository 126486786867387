<template>
  <div></div>
</template>

<script setup lang="ts">

import {useAuthStore} from "@/stores/auth";

const authStore = useAuthStore();

if (authStore.loggedIn) {
  authStore.logout();
}

window.location.href = "/login"
</script>