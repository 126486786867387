class FormatService {
    private formatter = new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'CZK'});

    toCurrency(val: number): string {
        return this.formatter.format(val);
    }

}

export default new FormatService();
