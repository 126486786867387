<template>
  <div class="mt-10">
    <h2 class="font-medium text-gray-900">Počet vstupenek</h2>

    <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <MyInput ref="fullInput"
               v-model.number="fullModel"
               type="number"
               label="Plná cena"
               name="fullprice"
               class="sm:col-span-3"/>

      <MyInput ref="discountInput"
               v-model.number="discountModel"
               type="number"
               label="Snížená cena"
               name="discount-price"
               class="sm:col-span-3"/>
    </div>
    <p v-if="!valid" class="mt-2 text-sm text-red-600" id="email-error">{{ errorMessage }}</p>

  </div>
</template>

<script lang="ts" setup>

import {computed, Ref, ref} from "vue";
import MyInput from "../../MyInput.vue";
import {ITicketCount} from "@/services/contracts/ITicketCount";

const props = defineProps<{ modelValue: ITicketCount }>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: ITicketCount): void
}>()

const fullInput = ref();
const discountInput = ref();

const valid = ref(true);
const errorMessage = ref('');

const fullModel = computed({
  get() {
    return props.modelValue.fullPriceCount;
  },
  set(value: number) {
    value = normalizeNumber(value);
    const emitData = {
      discountCount: props.modelValue.discountCount,
      fullPriceCount: value
    };
    if (validateCount(value, fullInput)) {
      validateTotalCount(emitData);
    }
    emit('update:modelValue', emitData)
  }
})

const discountModel = computed({
  get() {
    return props.modelValue.discountCount;
  },
  set(value: number) {
    value = normalizeNumber(value);
    const emitData = {
      discountCount: value,
      fullPriceCount: props.modelValue.fullPriceCount
    };
    if (validateCount(value, discountInput)) {
      validateTotalCount(emitData);
    }
    emit('update:modelValue', emitData)
  }
})

function normalizeNumber(value: number): number {
  if (!value.toString().match(/[0-9]+/)) {
    return 0;
  }
  return value;
}

function validateCount(value: number, input: Ref): boolean {
  console.log("VALIDATE", value)
  if (!Number.isInteger(value)) {
    input.value.setValid(false, 'Neplatná hodnota');
    return false;
  }
  if (value < 0) {
    input.value.setValid(false, 'Neplatná hodnota');
    return false;
  }
  if (value > 4) {
    input.value.setValid(false, 'Maximálně 4 vstupenky');
    return false;
  }
  input.value.setValid(true);
  return true;
}

function validateTotalCount(values: ITicketCount) {
  const totalCount = values.fullPriceCount + values.discountCount;
  if (totalCount < 1) {
    valid.value = false;
    errorMessage.value = 'Celkový počet vstupenek může být minimálně 1';
  } else if (totalCount > 4) {
    valid.value = false;
    errorMessage.value = 'Celkový počet vstupenek může být maximálně 4';
  } else {
    valid.value = true;
    errorMessage.value = '';
  }

  fullInput.value.setValid(valid.value);
  discountInput.value.setValid(valid.value);
}
</script>
