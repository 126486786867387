<template>
  <MyDialog ref="dialog" title="Prodejní místo" @cancel="cancel" @ok="ok">

    <Listbox as="div" v-model="selected">
      <div class="relative mt-2">
        <ListboxButton
            class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
          <span class="block truncate">{{ selected.name }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
          </span>
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
          <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption as="template"
                           v-for="ven in vendorList" :key="ven.id" :value="ven"
                           v-slot="{ active, selected }">

              <li class="relative cursor-default select-none py-2 pl-8 pr-4"
                  :class="active ? 'bg-indigo-600 text-white' : 'text-gray-900'">
                <span class="block truncate" :class="selected ? 'font-semibold' : 'font-normal'">{{ ven.name }}</span>
                <span v-if="selected"
                      :class="active ? 'text-white' : 'text-indigo-600'"
                      class="absolute inset-y-0 left-0 flex items-center pl-1.5">
                  <CheckIcon class="h-5 w-5" aria-hidden="true"/>
                </span>
              </li>

            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>

  </MyDialog>
</template>

<script lang="ts" setup>
import {Ref, ref} from 'vue'
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/20/solid';
import VendorService from "../../../services/VendorService";
import {useVendorStore} from "@/stores/vendor";
import MyDialog from "../MyDialog.vue";
import {IVendor} from "@/services/contracts/IVendor";

const emit = defineEmits<{ ok: [], cancel: [] }>()

const vendorStore = useVendorStore()
const dialog = ref()

const noVendor: IVendor = {id: 0, name: 'Nezadáno'}
const selected: Ref<IVendor> = ref(vendorStore.vendor || noVendor)
const vendorList: Ref<Array<IVendor>> = ref([])

function ok() {
  if (selected.value.id !== 0) {
    vendorStore.changeVendor(selected.value)
  } else {
    vendorStore.changeVendor(null)
  }
  emit('ok')
}

function cancel() {
  emit('cancel')
}

function show() {
  loadAvailableVendors();
  dialog.value.show()
  selected.value = vendorStore.vendor || noVendor
}

function loadAvailableVendors() {
  VendorService.findAvailableVendors().then(
      (response) => {
        vendorList.value = response.data;
        vendorList.value.unshift(noVendor);
      },
      (error) => {
        console.error(error)
      }
  );
}

defineExpose({
  show
})
</script>

<style>

</style>