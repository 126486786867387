import api from "./support/api";
import {IAccreditation} from "@/services/contracts/IAccreditation";
import {IAccreditationChange} from "@/services/contracts/IAccreditationChange";
import {IAccreditationRequest} from "@/services/contracts/IAccreditationRequest";
import {IAccreditationResponse} from "@/services/contracts/IAccreditationResponse";

class AccreditationService {

    findById(id: number) {
        return api.get<IAccreditation>('accreditation/' + id);
    }

    change(id: number, data: IAccreditationChange) {
        return api.put<IAccreditation>('accreditation/' + id, data);
    }

    printTickets(id: number, idVendor: number) {
        return api.put<IAccreditation>('accreditation/tickets/print/' + id + '/' + idVendor);
    }

    checkAvailabilityForScreening(data: IAccreditationRequest) {
        return api.post<IAccreditationResponse>('accreditation/check-for-screening', data);
    }

}

export default new AccreditationService();
