<template>
  <div :class="focused ? 'focused' : ''" class="card-reader-holder">
    <LaserScanner ref="scanner"/>
    <span class="card-reader-text">Klikni pro načítání karty</span>
    <input class="card-reader-input"
           ref="invisibleInput"
           v-model="data"
           @keydown="onKey"
           @focus="focus"
           @blur="blur"
           autocomplete="off"/>
  </div>
</template>

<script lang="ts" setup>
import LaserScanner from "./LaserScanner.vue"
import {onMounted, Ref, ref} from "vue"

const scanner = ref();
const invisibleInput = ref()

const emit = defineEmits<{ enterKeyUp: [value: string] }>()
const data: Ref<string> = ref('')
const focused = ref(false)

function onKey(e: KeyboardEvent) {
  if (e.key === 'Enter') {
    console.log('emit enterKeyUp', data.value)
    emit('enterKeyUp', fixCzechKeyboard(data.value))
    data.value = ''
  }
}

function fixCzechKeyboard(s: string) {
  let r = "";
  for (let i = 0; i < s.length; i++) {
    let c = s.charAt(i);
    r += translate(c);
  }
  return r;
}

function translate(c: string) {
  switch (c) {
    case '+':
      return 1;
    case 'ě':
      return 2;
    case 'š':
      return 3;
    case 'č':
      return 4;
    case 'ř':
      return 5;
    case 'ž':
      return 6;
    case 'ý':
      return 7;
    case 'á':
      return 8;
    case 'í':
      return 9;
    case 'é':
      return 0;
  }
  return c;
}

function focus() {
  focused.value = true;
}

function blur() {
  setTimeout(() => focused.value = false, 500)
}

function manualFocus() {
  invisibleInput.value.focus()
}

onMounted(() => {
  manualFocus()
})

defineExpose({
  manualFocus
})
</script>

<style>
.card-reader-holder {
  position: relative;
  border: 1px solid #f00;
  height: 6em;
  width: 100%;
  text-align: center;
  background: #fee;
}

.focused.card-reader-holder {
  border: none;
  background: none;
}

.card-reader-text {
  position: relative;
  top: -4em;
  color: #f00;
  font-weight: bold;
}

.preloader-scan {
  opacity: 0.3;
}

.diode {
  display: none;
}

.focused .preloader-scan {
  opacity: 1;
}

.focused .diode {
  display: block;
}

.focused .card-reader-text {
  display: none;
}

.card-reader-input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
</style>
