import api from "./support/api";
import {ISessionChange} from "@/services/contracts/ISessionChange";
import {ISessionItem} from "@/services/contracts/ISessionItem";
import {IVendor} from "@/services/contracts/IVendor";
import {ISessionitemChange} from "@/services/contracts/ISessionItemChange";

class SessionService {

    findAvailable(vendor: IVendor) {
        return api.get<Array<ISessionItem>>('session/available/' + vendor.id);
    }

    close(vendor: IVendor, data: ISessionChange) {
        return api.put<void>('session/close/' + vendor.id, data);
    }

    toggleItemExclusion(change: ISessionitemChange) {
        return api.put<Array<ISessionItem>>('session/item-exclusion/' + change.id + '/' + change.exclude);
    }

}

export default new SessionService();
