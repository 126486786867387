<template>
  <li :class="!item.editable ? 'disabled' :''"
      class="date-card col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
      @click="toggleClick">
    <div class="flex flex-1 flex-col p-2">
      <h3 class="mt-6 text-sm font-medium text-gray-900">{{ item.day }}</h3>
      <dl class="mt-1 flex flex-grow flex-col justify-between">

        <dd class="text-sm text-gray-500">{{ item.date }}</dd>
        <div class="p-2">
          <ToggleSwitch ref="toggle" v-model="checked" :disabled="!item.editable"/>
        </div>
      </dl>
    </div>
    <div>
      <div class="-mt-px flex divide-x divide-gray-200 justify-center p-2 rounded-b-lg"
           :class="item.checked ? 'checked' : ''">
        <dd class="text-sm text-gray-500">{{ item.capacity }}</dd>
      </div>
    </div>
  </li>
</template>

<script lang="ts" setup>
import ToggleSwitch from "../components/ToggleSwitch.vue";
import {computed, ref} from "vue";
import {IAccreditationDate} from "@/services/contracts/IAccreditationDate";

const props = defineProps<{ item: IAccreditationDate }>();
const emit = defineEmits<{ onchange: [item: IAccreditationDate] }>();

const item = computed(() => props.item);
const toggle = ref();

const checked = computed({
  get() {
    return item.value.checked;
  },
  set(newValue: boolean) {
    item.value.checked = newValue;
    item.value.changed = newValue;
    emit('onchange', item.value);
  }
});

function toggleClick() {
  //enabled.value = !enabled.value;
  // if (item.value.state === 0) {
  //   item.value.state = 1;
  // } else {
  //   item.value.state = 0;
  // }
}

</script>

<style scoped>
.date-card:hover {
  /*cursor: pointer;*/
}

.date-card.disabled {
  background: #eee;
}

.date-card .checked {
  background: #cfc;
}

</style>