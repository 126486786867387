<template>
  <div class="relative mt-2 rounded-md shadow-sm">
    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
      <MagnifyingGlassIcon aria-hidden="true" class="h-5 w-5 text-gray-400"/>
    </div>
    <input id="query" ref="input" v-model="query"
           autofocus
           class="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
           name="query"
           placeholder="Hledat..."
           spellcheck="false"
           type="text" @keyup="keypress"/>
  </div>
</template>

<script setup lang="ts">
import {MagnifyingGlassIcon} from '@heroicons/vue/20/solid'
import {nextTick, ref} from "vue";

defineExpose({
  focusInput
})

const props = defineProps<{
  query: string
}>();

const emit = defineEmits<{
  (e: 'search', value: string): void
}>();

const input = ref();
const query = ref(props.query);

function focusInput() {
  nextTick(() => {
    input.value.focus();
  });
}

function keypress() {
  emit('search', query.value);
}

</script>