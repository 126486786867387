<template>
  <MyDialog ref="dialog" :title="dialogTitle" ok-button-text="Tisk" @cancel="cancel" @ok="buyAndPrint">
    <TicketDialogNavigation v-model="data" :screening="screening"
                            @update:modelValue="checkSubmittable"></TicketDialogNavigation>
  </MyDialog>
</template>

<script lang="ts" setup>
import {computed, Ref, ref} from 'vue'
import TicketDialogNavigation from "./TicketDialogNavigation.vue";
import TicketService from "../../../services/TicketService";
import {useVendorStore} from "@/stores/vendor";
import MyDialog from "../MyDialog.vue";
import {ITicketPrintFormData} from "@/services/contracts/ITicketPrintFormData";
import {RequestMethod} from "@/services/contracts/RequestMethod";
import {IScreening} from "@/services/contracts/IScreening";
import {ITicketResponse} from "@/services/contracts/ITicketResponse";
import {ITicketCount} from "@/services/contracts/ITicketCount";
import TranslateService from "@/services/TranslateService";
import {usePrinterStore} from "@/stores/printer";

const emit = defineEmits<{
  (e: 'print', value: ITicketResponse): void,
  (e: 'error', value: string): void,
  (e: 'cancel'): void
}>();

const vendorStore = useVendorStore();
const screening: Ref<IScreening> = ref(createDefaultScreening());
const data: Ref<ITicketPrintFormData> = ref(createEmptyFormData());

const dialog = ref();
const dialogTitle = computed(() => {
  return `Tisk vstupenek - ${screening.value.code}`;
});

function createDefaultScreening(): IScreening {
  return {
    id: 0,
    title: '',
    state: '',
    theatre: '',
    date: '',
    time: '',
    code: '',
    blue: 0,
    green: 0,
    red: 0,
    available: false,
    collapsed: false
  }
}

function createEmptyFormData(): ITicketPrintFormData {
  return {
    method: RequestMethod[RequestMethod.ACCREDITATION],
    accreditation: {
      cards: []
    },
    money: {fullPriceCount: 0, discountCount: 0}
  };
}

function buyAndPrint() {
  internalBuyAndPrint();
}

function cancel() {
  emit('cancel');
}

function show(_screening: IScreening) {
  data.value = createEmptyFormData();
  screening.value = _screening;

  dialog.value.show();
  dialog.value.setSubmittable(false);
}

function internalBuyAndPrint() {

  if (vendorStore.vendor === null) {
    throw 'Vendor is not set!';
  }

  const ticketRequest = {
    idVendor: vendorStore.vendor.id,
    idScreening: screening.value.id,
    method: data.value.method,
    totalTickets: data.value.money.fullPriceCount + data.value.money.discountCount,
    discountTickets: data.value.money.discountCount,
    cards: data.value.accreditation.cards.map(x => x.number)
  }

  console.log('TicketRequest', ticketRequest);

  TicketService.buyAndPrintTickets(ticketRequest).then(
      (response) => {
        console.log('Ticket printed data:', response.data);
        emit('print', response.data);
      },
      (error) => {
        emit('error', TranslateService.translate(error?.response?.headers['x-error-message']))
      }
  );
}

function checkSubmittable(value: ITicketPrintFormData) {
  data.value = value;
  console.log(value);
  if (usePrinterStore().isNotAvailable) {
    dialog.value.setSubmittable(false);
    return;
  }
  if (data.value.method === RequestMethod[RequestMethod.ACCREDITATION]) {
    dialog.value.setSubmittable(data.value.accreditation.cards.length > 0);
  } else if (data.value.method === RequestMethod[RequestMethod.MONEY]) {
    dialog.value.setSubmittable(validateMoneyPart(data.value.money));
  }
}

function validateMoneyPart(value: ITicketCount) {
  if (!Number.isInteger(value.fullPriceCount)) {
    return false;
  }
  if (!Number.isInteger(value.discountCount)) {
    return false;
  }
  if (value.fullPriceCount < 0) {
    return false;
  }
  if (value.fullPriceCount > 4) {
    return false;
  }
  if (value.discountCount < 0) {
    return false;
  }
  if (value.discountCount > 4) {
    return false;
  }
  let ticketCount = value.fullPriceCount + value.discountCount;
  return 0 < ticketCount && ticketCount < 5;
}

defineExpose({
  show
})
</script>

<style>

</style>